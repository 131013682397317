// import './App.css';
// import Header from './components/Header/Header'
// import Hero from './components/Hero/Hero'
// import Aboutus from './components/AboutUs/Aboutus'

import React from "react";
//import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing.jsx";

function App() {
  return (
    <div className="App">
    {/* <Header />
    <Hero />
    <Aboutus /> */}
    <Landing />
    </div>
  );
}

export default App;
