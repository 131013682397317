import React ,{useState}from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import ProjectImg1 from "../../assets/img/projects/1.png";
import ProjectImg2 from "../../assets/img/projects/2.png";
import ProjectImg3 from "../../assets/img/projects/3.png";
import ProjectImg4 from "../../assets/img/projects/4.png";
import ProjectImg5 from "../../assets/img/projects/5.png";
import ProjectImg6 from "../../assets/img/projects/6.png";
import ProjectImg7 from "../../assets/img/projects/7.png";
import ProjectImg8 from "../../assets/img/projects/8.jpg";
import ProjectImg9 from "../../assets/img/projects/9.jpg";

export default function Projects() {




              const [projectbox,setProjectbox] =useState([
                {
                  img:ProjectImg1,
                  title:"室内搭建",
                  text:"室内斗屏吊装结构"
                },{
                  img:ProjectImg2,
                  title:"电竞赛事",
                  text:"东南亚 Arena of Valor"
                },{
                  img:ProjectImg3,
                  title:"室内实景",
                  text:"大众汽车TVC"
                },{
                  img:ProjectImg4,
                  title:"虚拟偶像",
                  text:"米哈游《纯真梦歌》"
                },{
                  img:ProjectImg5,
                  title:"户外实景",
                  text:"平面模特拍摄"
                },{
                  img:ProjectImg6,
                  title:"电竞赛事",
                  text:"守望先锋挑战者系列赛"
                },{
                  img:ProjectImg7,
                  title:"电竞赛事",
                  text:"Next系列赛"
                },{
                  img:ProjectImg8,
                  title:"电竞赛事",
                  text:"英雄联盟虎牙观赛场"
                },{
                  img:ProjectImg9,
                  title:"线下活动",
                  text:"Clot x Arcade1Up"
                }
              ]);


              const [numToShow,setNumToShow] =useState(3);


              const handleLoadMore = ()=>{
                setNumToShow(numToShow + 3);
              }


  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">产品和案例</h1>
            <p className="font13 mt-2">
            我们是生产制造LED屏幕的上游厂商，能够生产各类常规、拼接、柔性、高刷屏，满足不同的应用场景
              <br />
              我们拥有大约2000平方的室内、户外影棚，分别位于广州、深圳、上海等地
              <br />
              我们与国内外多支电竞战队、KOL、游戏厂商有良好的关系
            </p>
          </HeaderInfo>
          <div className="row textCenter">
                        
                {projectbox.slice(0,numToShow).map((project, index) => (
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                   <ProjectBox
                     img={project.img}
                     title={project.title}
                     text={project.text}
                  />  </div>
                ))} 

            
          </div>
        
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>

            {numToShow < projectbox.length && (
          <FullButton title="Load More" action={() => handleLoadMore()} />
        )}
              
            </div>
          </div>
        </div>
      </div>
      {/* <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddImage2} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">A few words about company</h4>
              <h2 className="font40 extraBold">A Study of Creativity</h2>
              <p className="font12">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
                diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                <div style={{ width: "190px" }}>
                  <FullButton title="Get Started" action={() => alert("clicked")} />
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <FullButton title="Contact Us" action={() => alert("clicked")} border />
                </div>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div> */}
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
