import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={192}
    height={192} {...props}>
    <defs>
      <style>
        {
          ".cls-1{fill:#27273d}.cls-3{fill:#db1b1b}.cls-4{fill:#c41c1c}.cls-5{fill:#32314b}.cls-7{fill:#4f4f74}.cls-8{fill:#3e436d}.cls-9{fill:#353b5e}"
        }
      </style>
    </defs>
    <circle id="BULINE" cx={256} cy={256} r={256} className="cls-1" />
    <g id="Icons">
      <path
        d="M506.54 308.83C482.19 424.87 379.27 512 256 512S29.81 424.87 5.46 308.83z"
        style={{
          fill: "#9e4830",
        }}
      />
      <path
        d="M75.77 72.39h19.18v249.52a9.59 9.59 0 0 1-9.59 9.59 9.59 9.59 0 0 1-9.59-9.59V72.39z"
        className="cls-3"
      />
      <path
        d="M87.77 331.19a9 9 0 0 1-2.41.31 9.59 9.59 0 0 1-9.59-9.59V72.39h4.83v249.52a9.59 9.59 0 0 0 7.17 9.28z"
        className="cls-4"
      />
      <path
        d="M56.6 72.39h19.17v249.52a9.59 9.59 0 0 1-9.59 9.59 9.59 9.59 0 0 1-9.59-9.59V72.39z"
        className="cls-3"
      />
      <path
        d="M68.6 331.19a9.1 9.1 0 0 1-2.41.31 9.59 9.59 0 0 1-9.59-9.59V72.39h4.83v249.52a9.59 9.59 0 0 0 7.17 9.28z"
        className="cls-4"
      />
      <path
        d="M37.42 72.39H56.6v249.52a9.59 9.59 0 0 1-9.6 9.59 9.59 9.59 0 0 1-9.59-9.59V72.39z"
        className="cls-3"
      />
      <path
        d="M49.43 331.19a9.17 9.17 0 0 1-2.42.31 9.59 9.59 0 0 1-9.59-9.59V72.39h4.83v249.52a9.58 9.58 0 0 0 7.18 9.28z"
        className="cls-4"
      />
      <path
        d="M18.25 72.39h19.17v249.52a9.59 9.59 0 0 1-9.59 9.59 9.59 9.59 0 0 1-9.59-9.59V72.39h.01z"
        className="cls-3"
      />
      <path
        d="M30.25 331.19a9 9 0 0 1-2.41.31 9.59 9.59 0 0 1-9.59-9.59V72.39h4.83v249.52a9.59 9.59 0 0 0 7.17 9.28z"
        className="cls-4"
      />
      <path
        d="M152.47 72.39h19.17v249.52a9.59 9.59 0 0 1-9.59 9.59 9.59 9.59 0 0 1-9.59-9.59V72.39z"
        className="cls-3"
      />
      <path
        d="M164.47 331.19a9.1 9.1 0 0 1-2.41.31 9.59 9.59 0 0 1-9.59-9.59V72.39h4.83v249.52a9.59 9.59 0 0 0 7.17 9.28z"
        className="cls-4"
      />
      <path
        d="M133.3 72.39h19.17v249.52a9.59 9.59 0 0 1-9.59 9.59 9.59 9.59 0 0 1-9.59-9.59V72.39z"
        className="cls-3"
      />
      <path
        d="M145.3 331.19a9.17 9.17 0 0 1-2.42.31 9.58 9.58 0 0 1-9.58-9.59V72.39h4.82v249.52a9.58 9.58 0 0 0 7.18 9.28z"
        className="cls-4"
      />
      <path
        d="M114.12 72.39h19.18v249.52a9.59 9.59 0 0 1-9.59 9.59 9.59 9.59 0 0 1-9.59-9.59V72.39z"
        className="cls-3"
      />
      <path
        d="M126.12 331.19a9 9 0 0 1-2.41.31 9.59 9.59 0 0 1-9.59-9.59V72.39H119v249.52a9.59 9.59 0 0 0 7.12 9.28z"
        className="cls-4"
      />
      <path
        d="M94.95 72.39h19.17v249.52a9.59 9.59 0 0 1-9.59 9.59 9.59 9.59 0 0 1-9.59-9.59V72.39z"
        className="cls-3"
      />
      <path
        d="M107 331.19a9.17 9.17 0 0 1-2.42.31 9.58 9.58 0 0 1-9.58-9.59V72.39h4.83v249.52a9.58 9.58 0 0 0 7.17 9.28z"
        className="cls-4"
      />
      <path
        d="M229.17 72.39h19.17v249.52a9.59 9.59 0 0 1-9.59 9.59 9.59 9.59 0 0 1-9.59-9.59V72.39z"
        className="cls-3"
      />
      <path
        d="M241.17 331.19a9.17 9.17 0 0 1-2.42.31 9.58 9.58 0 0 1-9.58-9.59V72.39H234v249.52a9.58 9.58 0 0 0 7.17 9.28z"
        className="cls-4"
      />
      <path
        d="M210 72.39h19.17v249.52a9.59 9.59 0 0 1-9.59 9.59 9.59 9.59 0 0 1-9.59-9.59V72.39z"
        className="cls-3"
      />
      <path
        d="M222 331.19a9 9 0 0 1-2.41.31 9.59 9.59 0 0 1-9.59-9.59V72.39h4.83v249.52a9.59 9.59 0 0 0 7.17 9.28z"
        className="cls-4"
      />
      <path
        d="M190.82 72.39H210v249.52a9.59 9.59 0 0 1-9.59 9.59 9.59 9.59 0 0 1-9.59-9.59V72.39z"
        className="cls-3"
      />
      <path
        d="M202.82 331.19a9.17 9.17 0 0 1-2.42.31 9.58 9.58 0 0 1-9.58-9.59V72.39h4.83v249.52a9.58 9.58 0 0 0 7.17 9.28z"
        className="cls-4"
      />
      <path
        d="M171.64 72.39h19.17v249.52a9.59 9.59 0 0 1-9.59 9.59 9.59 9.59 0 0 1-9.59-9.59V72.39z"
        className="cls-3"
      />
      <path
        d="M183.65 331.19a9.17 9.17 0 0 1-2.42.31 9.59 9.59 0 0 1-9.59-9.59V72.39h4.83v249.52a9.58 9.58 0 0 0 7.18 9.28z"
        className="cls-4"
      />
      <path
        d="M305.86 72.39H325v249.52a9.59 9.59 0 0 1-9.59 9.59 9.59 9.59 0 0 1-9.59-9.59V72.39z"
        className="cls-3"
      />
      <path
        d="M317.86 331.19a9 9 0 0 1-2.41.31 9.59 9.59 0 0 1-9.59-9.59V72.39h4.83v249.52a9.59 9.59 0 0 0 7.17 9.28z"
        className="cls-4"
      />
      <path
        d="M286.69 72.39h19.17v249.52a9.59 9.59 0 0 1-9.59 9.59 9.59 9.59 0 0 1-9.59-9.59V72.39z"
        className="cls-3"
      />
      <path
        d="M298.69 331.19a9.17 9.17 0 0 1-2.42.31 9.58 9.58 0 0 1-9.58-9.59V72.39h4.83v249.52a9.58 9.58 0 0 0 7.17 9.28z"
        className="cls-4"
      />
      <path
        d="M267.51 72.39h19.17v249.52a9.59 9.59 0 0 1-9.59 9.59 9.59 9.59 0 0 1-9.59-9.59V72.39h.01z"
        className="cls-3"
      />
      <path
        d="M279.52 331.19a9.17 9.17 0 0 1-2.42.31 9.59 9.59 0 0 1-9.59-9.59V72.39h4.83v249.52a9.58 9.58 0 0 0 7.18 9.28z"
        className="cls-4"
      />
      <path
        d="M248.34 72.39h19.17v249.52a9.59 9.59 0 0 1-9.59 9.59 9.59 9.59 0 0 1-9.59-9.59V72.39z"
        className="cls-3"
      />
      <path
        d="M260.34 331.19a9.1 9.1 0 0 1-2.41.31 9.59 9.59 0 0 1-9.59-9.59V72.39h4.83v249.52a9.59 9.59 0 0 0 7.17 9.28z"
        className="cls-4"
      />
      <path
        d="M382.56 72.39h19.17v249.52a9.59 9.59 0 0 1-9.59 9.59 9.59 9.59 0 0 1-9.59-9.59V72.39z"
        className="cls-3"
      />
      <path
        d="M394.56 331.19a9.17 9.17 0 0 1-2.42.31 9.58 9.58 0 0 1-9.58-9.59V72.39h4.83v249.52a9.58 9.58 0 0 0 7.17 9.28z"
        className="cls-4"
      />
      <path
        d="M363.38 72.39h19.17v249.52a9.59 9.59 0 0 1-9.55 9.59 9.59 9.59 0 0 1-9.59-9.59V72.39z"
        className="cls-3"
      />
      <path
        d="M375.39 331.19a9.17 9.17 0 0 1-2.42.31 9.59 9.59 0 0 1-9.59-9.59V72.39h4.83v249.52a9.58 9.58 0 0 0 7.18 9.28z"
        className="cls-4"
      />
      <path
        d="M344.21 72.39h19.17v249.52a9.59 9.59 0 0 1-9.59 9.59 9.59 9.59 0 0 1-9.59-9.59V72.39z"
        className="cls-3"
      />
      <path
        d="M356.21 331.19a9.1 9.1 0 0 1-2.41.31 9.59 9.59 0 0 1-9.59-9.59V72.39H349v249.52a9.59 9.59 0 0 0 7.21 9.28z"
        className="cls-4"
      />
      <path
        d="M325 72.39h19.17v249.52a9.59 9.59 0 0 1-9.59 9.59 9.59 9.59 0 0 1-9.59-9.59V72.39z"
        className="cls-3"
      />
      <path
        d="M337 331.19a9.17 9.17 0 0 1-2.42.31 9.58 9.58 0 0 1-9.58-9.59V72.39h4.83v249.52a9.58 9.58 0 0 0 7.17 9.28z"
        className="cls-4"
      />
      <path
        d="M459.6 72.39h19.17v249.52a9.59 9.59 0 0 1-9.59 9.59 9.59 9.59 0 0 1-9.59-9.59V72.39z"
        className="cls-3"
      />
      <path
        d="M471.6 331.19a9.1 9.1 0 0 1-2.41.31 9.59 9.59 0 0 1-9.59-9.59V72.39h4.83v249.52a9.59 9.59 0 0 0 7.17 9.28z"
        className="cls-4"
      />
      <path
        d="M440.43 72.39h19.17v249.52a9.59 9.59 0 0 1-9.6 9.59 9.59 9.59 0 0 1-9.59-9.59V72.39z"
        className="cls-3"
      />
      <path
        d="M452.43 331.19a9.17 9.17 0 0 1-2.42.31 9.58 9.58 0 0 1-9.58-9.59V72.39h4.83v249.52a9.58 9.58 0 0 0 7.17 9.28z"
        className="cls-4"
      />
      <path
        d="M421.25 72.39h19.17v249.52a9.59 9.59 0 0 1-9.59 9.59 9.59 9.59 0 0 1-9.59-9.59V72.39z"
        className="cls-3"
      />
      <path
        d="M433.25 331.19a9 9 0 0 1-2.41.31 9.59 9.59 0 0 1-9.59-9.59V72.39h4.83v249.52a9.59 9.59 0 0 0 7.17 9.28z"
        className="cls-4"
      />
      <path
        d="M402.08 72.39h19.17v249.52a9.59 9.59 0 0 1-9.59 9.59 9.59 9.59 0 0 1-9.59-9.59V72.39z"
        className="cls-3"
      />
      <path
        d="M414.08 331.19a9.17 9.17 0 0 1-2.42.31 9.58 9.58 0 0 1-9.58-9.59V72.39h4.83v249.52a9.58 9.58 0 0 0 7.17 9.28z"
        className="cls-4"
      />
      <rect
        width={492.65}
        height={22.97}
        x={8.58}
        y={49.42}
        className="cls-5"
        rx={6.22}
      />
      <path
        d="M501.23 57.88v8.29a6.23 6.23 0 0 1-6.23 6.22H14.8a6.22 6.22 0 0 1-6.22-6.22v-8.29a6.22 6.22 0 0 0 6.22 6.22H495a6.23 6.23 0 0 0 6.23-6.22z"
        className="cls-1"
      />
      <path
        d="M57.8 93.03 34.83 116l70.12 215.8h345.48L57.8 93.03z"
        style={{
          fill: "#e55a5a",
        }}
      />
      <path
        d="m25.286 93.493 9.9-9.9a9.17 9.17 0 0 1 12.968 0l9.546 9.546-22.868 22.868-9.546-9.546a9.17 9.17 0 0 1 0-12.968z"
        className="cls-7"
      />
      <path
        d="M79.14 110 51.7 137.43a1.47 1.47 0 0 1-2.26-.2L34.83 116l22.88-22.87 21.22 14.61a1.48 1.48 0 0 1 .21 2.26z"
        className="cls-8"
      />
      <path
        d="m34.83 116 .53 19.77a2.06 2.06 0 0 0 3.52 1.4l6.23-6.23zM57.8 93l19.76.53A2.06 2.06 0 0 1 79 97.08l-6.23 6.24z"
        className="cls-5"
      />
      <path
        d="M31.356 112.518 54.323 89.55l3.479 3.479-22.967 22.967z"
        className="cls-9"
      />
      <path
        d="M438.62 93.03 461.59 116l-70.12 215.8H45.99L438.62 93.03z"
        style={{
          fill: "#ea7a7a",
        }}
      />
      <path
        d="m461.579 115.99-22.875-22.875 9.546-9.546a9.17 9.17 0 0 1 12.968 0l9.9 9.9a9.17 9.17 0 0 1 .007 12.975l-9.546 9.546z"
        className="cls-7"
      />
      <path
        d="m417.28 110 27.44 27.43a1.47 1.47 0 0 0 2.26-.2L461.59 116l-22.88-22.87-21.22 14.61a1.48 1.48 0 0 0-.21 2.26z"
        className="cls-8"
      />
      <path
        d="m461.59 116-.53 19.77a2.06 2.06 0 0 1-3.52 1.4l-6.24-6.23zm-22.97-23-19.77.53a2.06 2.06 0 0 0-1.4 3.52l6.23 6.24z"
        className="cls-5"
      />
      <path
        d="M461.592 116.006 438.626 93.04l3.479-3.48 22.966 22.967z"
        className="cls-9"
      />
    </g>
  </svg>
  );
}

export default SvgComponent;
