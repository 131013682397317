import * as React from "react";

function SvgComponent(props) {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" width={35.459} height={43.42} viewBox="0 0 35.459 43.42" {...props}>
    //   <g data-name="Group 70">
    //     <path
    //       data-name="Path 121"
    //       d="M24.571 12.115H4.778a3.425 3.425 0 01-3.412-3.411V4.78a3.425 3.425 0 013.411-3.415h19.792a3.427 3.427 0 013.413 3.412v3.924a3.424 3.424 0 01-3.408 3.414z"
    //       fill="#f2b300"
    //     />
    //     <path
    //       data-name="Path 122"
    //       d="M30.981 4.552h-1.647a4.782 4.782 0 00-4.765-4.55H4.779A4.787 4.787 0 00.001 4.781v3.922a4.787 4.787 0 004.778 4.778h19.79a4.785 4.785 0 004.776-4.778V7.282h1.636a1.836 1.836 0 011.748 1.876 4.152 4.152 0 01-2.31 3.309l-13.158 5.54a6.889 6.889 0 00-3.98 5.995v1.5a4.784 4.784 0 00-3.555 4.612v8.531a4.782 4.782 0 004.778 4.775h.339a4.784 4.784 0 004.776-4.776v-8.531a4.782 4.782 0 00-3.611-4.626V24a4.292 4.292 0 012.311-3.478l13.16-5.542a6.75 6.75 0 003.98-5.824 4.553 4.553 0 00-4.478-4.604zm-4.364 4.15a2.069 2.069 0 01-2.048 2.047H4.779a2.069 2.069 0 01-2.048-2.047V4.78a2.07 2.07 0 012.048-2.049h19.79a2.07 2.07 0 012.048 2.049v3.922zM16.89 30.114v8.531a2.067 2.067 0 01-2.047 2.046h-.339a2.07 2.07 0 01-2.05-2.046v-8.531a2.071 2.071 0 012.05-2.048h.339a2.066 2.066 0 012.047 2.047z"
    //       fill="#0b093b"
    //     />
    //   </g>
    // </svg>
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={192}
    height={192}
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 6.827 6.827"
    {...props}
  >
    <defs>
      <clipPath id="id0">
        <path d="M3.413 0a3.413 3.413 0 1 1 0 6.827 3.413 3.413 0 0 1 0-6.827z" />
      </clipPath>
      <style>
        {".fil3{fill:#424242}.fil1{fill:#e64a19}.fil4{fill:#fffffe}"}
      </style>
    </defs>
    <g id="Layer_x0020_1">
      <path
        d="M3.413 0a3.413 3.413 0 1 1 0 6.827 3.413 3.413 0 0 1 0-6.827z"
        style={{
          fill: "#ff7043",
        }}
      />
      <g
        style={{
          clipPath: "url(#id0)",
        }}
      >
        <g id="_328322328">
          <path
            id="_3293861761"
            d="m4.066 2.213 3.786 3.786.008.009.007.009.008.01.007.008.007.01.006.01.007.01.006.01.006.01.006.01.006.01.005.011.005.01.005.012.004.01.004.012.004.011.004.012.004.011.003.012.003.012.002.012.002.012.002.012.002.012.002.012v.013l.001.012v.013l.001.012-3.786-3.786v-.025l-.002-.013v-.012l-.002-.012-.002-.013-.002-.012-.002-.012-.003-.012-.003-.011-.003-.012-.003-.012-.004-.011-.004-.011-.004-.011-.005-.012-.004-.01-.005-.011-.006-.01-.005-.011-.006-.01-.006-.01-.006-.01-.007-.01-.006-.01-.007-.01-.008-.01-.007-.008-.008-.01z"
            className="fil1"
          />
          <path
            id="_329386272"
            d="m3.067 1.956 3.787 3.786.01.01.01.012.009.01.01.012.008.012.009.012.008.012.009.012.008.012.007.013.008.013.007.013.006.013.007.013.006.014.006.013.005.014.006.014.004.014.005.015.004.014.004.015.004.014.003.015.003.015.002.015.003.016.001.015.002.015v.016l.002.016-3.787-3.787V2.36l-.001-.016-.002-.015-.002-.016-.002-.015-.002-.015-.003-.015-.003-.015-.004-.015-.004-.014-.004-.015-.005-.014-.005-.014-.005-.014-.005-.014-.006-.014-.006-.013-.007-.013-.007-.014-.007-.013-.007-.012-.008-.013-.008-.012-.008-.013-.008-.012-.009-.012L3.106 2l-.01-.012-.009-.01-.01-.012z"
            className="fil1"
          />
          <path
            id="_329387496"
            d="m4.05 2.888 3.786 3.786-.009.009-.008.008-.01.007-.008.008-.01.007-.009.008-.01.006-.009.007-.01.007-.01.006-.01.006-.01.006-.01.005-.011.006-.011.005-.01.004-.012.005-.011.004-.011.004-.012.004-.011.003-.012.003-.012.003-.012.003-.012.002-.012.002-.012.002-.012.001-.013.001-.012.001H7.49L3.703 3.033h.013l.012-.001h.013l.012-.002.013-.001.012-.002.012-.002.012-.002.012-.003.012-.003.011-.003.012-.003.011-.004.012-.004.01-.004.012-.005.01-.005.012-.005.01-.005.01-.006.011-.005.01-.006.01-.007.01-.006.01-.007.01-.007.009-.007.009-.007.009-.008.008-.008.009-.008z"
            className="fil1"
          />
          <path
            id="_329387952"
            d="m5.28 4.66 3.786 3.786-.123-.063-3.786-3.787z"
            className="fil1"
          />
          <path
            id="_329387568"
            d="m5.157 4.596 3.786 3.787-.424-.217L4.732 4.38z"
            className="fil1"
          />
          <path
            id="_329387976"
            d="M4.732 4.38 8.52 8.166l-.047-.024-3.786-3.786z"
            className="fil1"
          />
          <path
            id="_329387520"
            d="m4.686 4.356 3.786 3.786v.477L4.686 4.833z"
            className="fil1"
          />
          <path
            id="_329387880"
            d="m4.686 4.833 3.786 3.786V8.637l-.001.006v.006l-.002.006v.006l-.002.005-.001.006-.002.006-.001.005-.002.006-.002.005-.002.006-.002.005-.003.005-.002.006-.003.005-.003.005-.002.005-.004.005-.003.005-.003.005-.003.004-.004.005-.003.005-.004.004-.004.004-.004.005-.004.004-.004.004-.004.004L4.61 5l.005-.004.004-.004.004-.004.004-.005.004-.004.003-.005.004-.004.003-.005.004-.005.003-.004.003-.005.003-.005.003-.005.003-.005.003-.006.002-.005.002-.005.003-.005.002-.006.002-.005.001-.006.002-.005.002-.006V4.88l.002-.006.001-.005.001-.006v-.006l.001-.006v-.006l.001-.006z"
            className="fil1"
          />
          <path
            id="_329387616"
            d="m2.607 3.032 3.787 3.786h-.017l-.016-.001-.016-.001-.017-.002-.016-.001-.015-.003-.016-.002-.016-.003L6.25 6.8l-.015-.003-.016-.004-.015-.005-.015-.005-.014-.005-.015-.005-.014-.006-.014-.006-.014-.007-.014-.007-.014-.007-.013-.008-.013-.007-.013-.008-.013-.009-.013-.009-.012-.009L6 6.682l-.012-.01-.012-.01-.011-.01-.011-.01-3.787-3.786.011.01.012.01.012.01.011.01.013.01.012.008.012.009.013.008.013.008.014.008.013.008.014.007.013.007.014.006.015.006.014.006.014.006.015.005.015.005.015.004.015.004.016.004.015.004.016.003.015.002.016.002.016.002.016.002h.017l.016.001z"
            className="fil1"
          />
          <path
            id="_329387328"
            d="m4.432 5.068 3.786 3.786h-2.63L1.8 5.068z"
            className="fil1"
          />
          <path
            id="_329387424"
            d="m1.801 5.068 3.786 3.786H5.568l-.006-.001h-.007L5.55 8.85h-.006l-.007-.002H5.53l-.006-.002-.006-.002-.006-.001-.006-.002L5.5 8.84l-.006-.002-.005-.002-.006-.003-.006-.002-.005-.003-.006-.002-.005-.003-.005-.003-.006-.003-.005-.003-.005-.003-.005-.004-.005-.003-.004-.004-.005-.003-.005-.004L1.63 5.007l.005.003.004.004.005.004.005.003.005.003.005.004.005.003.005.003.006.003.005.002.006.003.005.003.006.002.005.002.006.003.006.002.006.001.006.002.006.002.006.001.006.002.006.001.006.001.007.001.006.001h.006l.007.001H1.795z"
            className="fil1"
          />
          <path
            id="_329388600"
            d="m4.611 4.999 3.787 3.786-.005.004-.004.004-.005.004-.004.003-.005.004-.005.003-.005.004-.005.003-.005.003-.005.003-.006.003-.005.003-.005.002-.006.003-.006.002-.005.003-.006.002-.006.002-.006.002-.006.002-.006.001-.006.002-.006.001-.006.001-.006.001-.007.001-.006.001h-.006l-.007.001h-.006l-.007.001h-.006L4.432 5.068H4.45l.006-.001.007-.001h.006l.006-.002h.007l.006-.002.006-.002.006-.001.006-.002.006-.002.006-.001.006-.002.005-.003.006-.002.006-.002.005-.003.006-.003.005-.002.005-.003.006-.003.005-.003.005-.004.005-.003.005-.003.004-.004.005-.004.005-.003.004-.004z"
            className="fil1"
          />
          <path
            id="_329388408"
            d="m4.193 2.542 3.786 3.786v.025l-.001.013-.001.012-.002.013-.002.012-.002.012-.002.012-.002.012-.003.012-.003.011-.004.012-.004.011-.004.012-.004.01-.004.012-.005.01-.005.011-.005.011-.006.01-.006.01-.006.011-.006.01-.007.01-.006.01-.007.01-.007.008-.008.01-.007.009-.008.008-.008.009-.008.008-3.787-3.786.009-.008.008-.009.007-.009.008-.009.007-.009.008-.01.006-.009.007-.01.007-.01.006-.01.006-.01.006-.01.005-.01.006-.01.005-.012.004-.01.005-.012.004-.01.004-.012.004-.011.003-.012.003-.012.003-.011.003-.012.002-.012.002-.012.002-.013.001-.012.001-.012.001-.013v-.012z"
            className="fil1"
          />
          <path
            id="_329388648"
            d="m5.28 3.44 3.786 3.786v.139L5.28 3.579z"
            className="fil1"
          />
          <path
            id="_329388480"
            d="M4.624 3.113 8.41 6.899l.004.004.004.005.004.004.003.005.004.005.003.004.003.005.003.005.004.005.002.005.003.005.003.005.002.005.003.006.002.005.002.006.002.005.002.006.001.005.002.006.001.005.001.006.001.006.001.006.001.006v.006l.001.006v.012L4.686 3.266V3.248l-.001-.005-.001-.006V3.23l-.002-.006-.001-.006-.001-.005-.002-.006-.002-.006-.001-.005-.002-.006-.002-.005-.003-.006-.002-.005-.003-.005-.002-.005-.003-.005-.003-.005-.003-.005-.003-.005-.003-.005-.004-.005-.003-.004-.004-.005-.003-.004-.004-.005z"
            className="fil1"
          />
          <path
            id="_329388576"
            d="m5.28 4.52 3.786 3.787v.139L5.28 4.659z"
            className="fil1"
          />
          <path
            id="_329388528"
            d="m4.686 3.266 3.786 3.787v.477L4.686 3.743z"
            className="fil1"
          />
          <path
            id="_329388696"
            d="m5.28 3.579 3.786 3.786v.942L5.28 4.521z"
            className="fil1"
          />
          <path
            id="_328322904"
            d="m4.732 3.72.425-.217.123-.063V4.659l-.123-.063-.425-.216-.046-.024v.477a.226.226 0 0 1-.075.166.265.265 0 0 1-.18.069h-2.63a.265.265 0 0 1-.18-.07.226.226 0 0 1-.074-.165V3.267c0-.065.028-.124.074-.167a.265.265 0 0 1 .18-.068h.806a.634.634 0 0 1-.636-.637.634.634 0 0 1 .636-.636.634.634 0 0 1 .636.615.49.49 0 1 1 .46.658h.729c.07 0 .133.026.18.068a.226.226 0 0 1 .074.166v.477l.046-.023z"
            className="fil1"
          />
        </g>
      </g>
      <path
        d="M3.413 0a3.413 3.413 0 1 1 0 6.827 3.413 3.413 0 0 1 0-6.827z"
        style={{
          fill: "none",
        }}
      />
      <path
        d="m4.732 3.72.425-.217.123-.063V4.659l-.123-.063-.425-.216-.046-.024V3.743z"
        className="fil3"
      />
      <path
        d="M3.703 2.052a.488.488 0 0 1 .346.836.488.488 0 0 1-.835-.346.488.488 0 0 1 .49-.49z"
        className="fil4"
      />
      <path
        d="M2.607 1.759a.634.634 0 0 1 .637.636.634.634 0 0 1-.637.637.634.634 0 0 1-.636-.637.634.634 0 0 1 .636-.636z"
        className="fil4"
      />
      <path d="M2.056 4.39v-.001zM2.056 3.625z" className="fil3" />
      <path
        d="M4.686 4.05v.783a.226.226 0 0 1-.075.166.265.265 0 0 1-.18.069h-2.63a.265.265 0 0 1-.18-.07.226.226 0 0 1-.074-.165V4.05h3.14z"
        style={{
          fill: "#d5d5d4",
        }}
      />
      <path
        d="M1.801 3.032h2.63c.07 0 .134.026.18.068a.226.226 0 0 1 .075.166v.783h-3.14v-.782c0-.065.03-.124.075-.167a.265.265 0 0 1 .18-.068z"
        className="fil4"
      />
      <path
        d="M2.056 3.456h1.357a.17.17 0 0 1 .17.17v.763a.17.17 0 0 1-.17.17H2.056a.17.17 0 0 1-.17-.17v-.764a.17.17 0 0 1 .17-.17zM3.922 3.54h.255v.17h-.255zM2.607 2.098a.296.296 0 0 1 .21.507.296.296 0 0 1-.507-.21.296.296 0 0 1 .297-.297zM3.703 2.33a.211.211 0 0 1 .15.362.211.211 0 0 1-.362-.15.211.211 0 0 1 .212-.212z"
        className="fil3"
      />
    </g>
  </svg>
        );
}

export default SvgComponent;
