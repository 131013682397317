import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    viewBox="0 0 512 512"
    width={192} height={192} 
    {...props}
  >
    <style>
      {
        ".st0{fill:#6770e6}.st3{fill:#d476e2}.st6{fill:#69ebfc}.st7{fill:#ff8354}.st8{fill:#339}.st9{fill:#e0734a}.st19{fill:#5fd4e3}.st20{fill:#ffe4d1}"
      }
    </style>
    <path
      d="M512 512H0l26.757-33.713a106 106 0 0 1 59.717-37.51L191.924 417h128.152l105.451 23.776a106.002 106.002 0 0 1 59.717 37.511L512 512z"
      className="st3"
    />
    <path
      d="M512 512H0l26.757-33.713a106 106 0 0 1 59.717-37.51L191.924 417h128.152l105.451 23.776a106.002 106.002 0 0 1 59.717 37.511L512 512z"
      className="st7"
    />
    <path id="XMLID_1236_" d="M204 334.035h104.001V417H204z" className="st20" />
    <path
      d="M256 488c35.388 0 64.076-28.688 64.076-64.076V417L308 414H204l-12.076 3v6.924C191.924 459.312 220.612 488 256 488z"
      className="st20"
    />
    <path
      id="XMLID_1122_"
      d="M332.824 114.431c-3.263-12.621-17.191-30.509-69.97-30.509-81.541 0-93.15 12.075-114.85-8.779-18.31-17.595-29.061 67.083-4.649 93.976-10.305 38.088-2.062 76.235-2.062 76.235h229.415s19.255-89.112-37.884-130.923z"
      className="st8"
    />
    <path
      id="XMLID_1123_"
      d="M148.003 95.17c21.7 20.854 33.31 8.779 114.85 8.779 52.779 0 66.708 17.888 69.97 30.509 30.478 22.301 39.219 58.058 40.742 86.573 1.521-29.464-2.504-78.621-40.742-106.601-3.263-12.621-17.191-30.509-69.97-30.509-81.541 0-93.15 12.075-114.85-8.779-12.002-11.533-20.755 20.877-18.487 52.151 1.679-23.225 9.086-41.157 18.487-32.123z"
      className="st0"
    />
    <path
      id="XMLID_2599_"
      d="M164.459 249.361s-9.89-18.118-29.671-18.118-33.791 86.964 29.671 72.47v-54.352z"
      className="st20"
    />
    <path
      id="XMLID_979_"
      d="M347.541 249.361s9.89-18.118 29.671-18.118 33.791 86.964-29.671 72.47v-54.352z"
      className="st20"
    />
    <path
      id="XMLID_816_"
      d="M256 132.827c-151.545 0-122.569 248.543 0 248.543 122.57 0 151.546-248.543 0-248.543z"
      style={{
        fill: "#ffefe4",
      }}
    />
    <path
      id="XMLID_2517_"
      d="M256 119.297s-103.079-14.541-103.079 117.716c0 0 12.979-54.665 42.754-69.573 13.869-6.944 18.947 10.647 60.324 10.647 41.378 0 46.456-17.591 60.324-10.647 29.776 14.908 42.754 69.573 42.754 69.573C359.078 104.756 256 119.297 256 119.297z"
      className="st8"
    />
    <path
      d="M154.9 252h13.6v-78h-13.6c-12.371 0-22.4 10.029-22.4 22.4v33.2c0 12.371 10.029 22.4 22.4 22.4zM357.1 252h-13.6v-78h13.6c12.371 0 22.4 10.029 22.4 22.4v33.2c0 12.371-10.029 22.4-22.4 22.4z"
      className="st19"
    />
    <path
      d="M379.5 231v-37c5.523 0 10 4.477 10 10v17c0 5.523-4.477 10-10 10zM132.5 231v-37c-5.523 0-10 4.477-10 10v17c0 5.523 4.477 10 10 10z"
      className="st6"
    />
    <path
      d="M324.122 187H187.878C165.302 187 147 205.302 147 227.878v37.244C147 287.698 165.302 306 187.878 306h30.826c6.355 0 12.21-3.445 15.296-9 3.086-5.555 8.941-9 15.296-9h13.409c6.355 0 12.21 3.445 15.296 9 3.086 5.555 8.941 9 15.296 9h30.826C346.698 306 365 287.698 365 265.122v-37.244C365 205.302 346.698 187 324.122 187z"
      style={{
        fill: "#b665c2",
      }}
    />
    <path
      d="M324.122 278H187.878C165.302 278 147 259.698 147 237.122v-37.244C147 177.302 165.302 159 187.878 159h136.244C346.698 159 365 177.302 365 199.878v37.244C365 259.698 346.698 278 324.122 278z"
      className="st3"
    />
    <circle cx={178.5} cy={193.5} r={13.5} className="st6" />
    <path
      d="M259.5 199h-50a5.5 5.5 0 1 1 0-11h50a5.5 5.5 0 1 1 0 11zM237.5 86.338V159h37V86.338A8.338 8.338 0 0 0 266.162 78h-20.324a8.338 8.338 0 0 0-8.338 8.338z"
      className="st6"
    />
    <path
      d="m77.501 424.041-54.5-27.249L23 333.016l54.5 27.249z"
      className="st0"
    />
    <path
      d="M77.501 424.041 132 396.791l-.001-63.776-54.499 27.25z"
      style={{
        fill: "#5861c7",
      }}
    />
    <path
      d="M77.499 305.766 23 333.016l54.5 27.249 54.499-27.25z"
      style={{
        fill: "#8f95e6",
      }}
    />
    <path
      id="XMLID_139_"
      d="m451 99.026-32.157 16.906c-3.751 1.972-8.136-1.213-7.419-5.39l6.141-35.807-26.015-25.36c-3.035-2.958-1.36-8.112 2.834-8.722l35.953-5.224L446.415 2.85c1.876-3.8 7.295-3.8 9.171 0l16.078 32.579 35.953 5.224c4.194.609 5.869 5.764 2.834 8.722l-26.016 25.359 6.141 35.807c.716 4.177-3.668 7.363-7.419 5.39L451 99.026z"
      className="st7"
    />
    <path
      d="M417.565 74.734 391.55 49.375a5.017 5.017 0 0 1-1.488-2.923l-.062-.057v11.87h.039a5.02 5.02 0 0 0 1.511 3.071l24.258 23.646 1.757-10.248zM483.157 115.931 451 99.026l-32.157 16.906c-3.406 1.791-7.32-.674-7.478-4.269v11.444c-.01.173-.007.342 0 .51v.001c.155 3.598 4.071 6.066 7.479 4.274L451 110.986l32.157 16.906c3.299 1.734 7.084-.521 7.462-3.93h.003v-.057c.03-.304.026-.617 0-.937v-11.013h-.001c-.35 3.435-4.151 5.718-7.464 3.976zM512 46.442l-.062.012a5.023 5.023 0 0 1-1.488 2.921l-26.016 25.359 1.758 10.248 24.258-23.646a5.02 5.02 0 0 0 1.495-4.305l.055-.011V46.442z"
      className="st9"
    />
    <path
      id="XMLID_152_"
      d="M23.411 234.86a3.533 3.533 0 0 1 3.533 3.533v10.494a3.533 3.533 0 0 1-7.066 0v-10.494a3.533 3.533 0 0 1 3.533-3.533z"
      className="st7"
    />
    <path
      id="XMLID_151_"
      d="M28.73 229.585a3.533 3.533 0 0 1 3.533-3.533h10.494a3.533 3.533 0 0 1 0 7.066H32.263a3.533 3.533 0 0 1-3.533-3.533z"
      className="st7"
    />
    <path
      id="XMLID_150_"
      d="M23.455 224.266a3.533 3.533 0 0 1-3.533-3.533v-10.494a3.533 3.533 0 0 1 7.066 0v10.494a3.533 3.533 0 0 1-3.533 3.533z"
      className="st7"
    />
    <path
      id="XMLID_149_"
      d="M18.136 229.541a3.533 3.533 0 0 1-3.533 3.533H4.109a3.533 3.533 0 0 1 0-7.066h10.494a3.533 3.533 0 0 1 3.533 3.533z"
      className="st7"
    />
    <path
      id="XMLID_148_"
      d="M470.925 211.985a3.533 3.533 0 0 1 3.533 3.533v10.494a3.533 3.533 0 0 1-7.066 0v-10.494a3.533 3.533 0 0 1 3.533-3.533z"
      className="st6"
    />
    <path
      id="XMLID_147_"
      d="M476.244 206.71a3.533 3.533 0 0 1 3.533-3.533h10.494a3.533 3.533 0 0 1 0 7.066h-10.494a3.533 3.533 0 0 1-3.533-3.533z"
      className="st6"
    />
    <path
      id="XMLID_146_"
      d="M470.969 201.391a3.533 3.533 0 0 1-3.533-3.533v-10.494a3.533 3.533 0 0 1 7.066 0v10.494a3.533 3.533 0 0 1-3.533 3.533z"
      className="st6"
    />
    <path
      id="XMLID_140_"
      d="M465.65 206.666a3.533 3.533 0 0 1-3.533 3.533h-10.494a3.533 3.533 0 0 1 0-7.066h10.494a3.533 3.533 0 0 1 3.533 3.533z"
      className="st6"
    />
    <circle cx={90} cy={128} r={5} className="st3" />
    <circle cx={285} cy={46} r={5} className="st6" />
    <circle cx={448} cy={334} r={5} className="st7" />
  </svg>
  );
}

export default SvgComponent;
